img {
  width: 100%;
}

body {
  margin-top: 50px
}

.btn {
  border-radius: 20px;
  margin: 20px;
}