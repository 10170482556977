.details-section {
    margin-top: 100px;
}

.details-section h2 {
    margin-bottom: 50px;
}

.detail-buttons {
    display: flex;
    justify-content: space-between;
}

.details-spinner {
    margin-top: 185px;
}