.spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-top-color: black;
    border-left-color: black;
    border-right-color: rgb(179, 179, 179);
    border-bottom-color: rgb(179, 179, 179);
    animation: spinner .3s infinite linear;
    position: relative;
    left: 48%;
    margin-bottom: 15px;
}


@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}